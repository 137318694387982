<template>
  <iframe class="prism-player" :src="pdfUrl" width="100%" height="100%" id="pdfiframe"></iframe>
</template>

<script>
    export default {
      name: "showFile.vue",
      data() {
        return {
          pdfUrl: '',
          videoId: this.$route.query.videoId,
        }
      },
      mounted() {
        this.setIframeHeight();
        this.init();
      },
      methods: {
        init(){
          var newUrl = `/api/showTextFile?videoId=${this.videoId}&access_token=${sessionStorage.getItem("ACCESS_TOKEN")}`;
          let projectName='/artworkreview';
          if((process.env.NODE_ENV === 'production' && process.env.VUE_APP_PRODUCT_PUBLISH_ENV === 'jiaokeyuan') || process.env.NODE_ENV === 'development'){
            projectName='';
          }
          this.pdfUrl = `pdfjs/web/viewer.html?file=${encodeURIComponent(`${projectName}${newUrl}`)}`;
        },
        setIframeHeight(){
          var iframe = document.getElementById("pdfiframe");
          try{
            var bHeight = document.body.scrollHeight;
            var dHeight = document.documentElement.scrollHeight;
            var height = Math.max(bHeight, dHeight);
            iframe.height = height - 10;
            console.log(height);
            // eslint-disable-next-line no-empty
          }catch (ex){

          }
        },

      }
    }
</script>

<style scoped>

</style>
